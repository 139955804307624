<template>
  <div class="container">
    <PublicHeader :totalName="$t('auth').high" />
    <div class="content">
      <div class="form">
        <div>{{ $t('auth').zmz }}：</div>
        <van-uploader
          class="img-box"
          preview-size="120"
          :preview-image="false"
          :after-read="justAfterRead"
        >
          <img class="upImage" v-if="ObverseImage" :src="ObverseImage" alt="" />
        </van-uploader>
        <div>{{ $t('auth').bmz }}：</div>
        <van-uploader
          class="img-box"
          preview-size="120"
          :preview-image="false"
          :after-read="justAfterRead1"
        >
          <img class="upImage" v-if="ReverseImage" :src="ReverseImage" alt="" />
        </van-uploader>
        <div v-if="basicConfig.IsHandCard">
          <div>{{ $t('auth').scz }}：</div>
          <van-uploader
            class="img-box"
            preview-size="120"
            :preview-image="false"
            :after-read="justAfterRead2"
          >
            <img class="upImage" v-if="HoldImage" :src="HoldImage" alt="" />
          </van-uploader>
        </div>
      </div>
      <div class="btn" @click="handleSub">{{ $t('auth').qrz }}</div>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import userApi from '@/api/user'
import transactionApi from '@/api/transaction'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader
  },

  data() {
    return {
      totalName: '高级身份认证',
      ObverseImage: '',
      ReverseImage: '',
      HoldImage: ''
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    console.log(this.basicConfig.IsHandCard)
  },
  methods: {
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.ObverseImage = await transactionApi.upImage(formdata)
    },
    async justAfterRead1(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.ReverseImage = await transactionApi.upImage(formdata)
    },
    async justAfterRead2(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.HoldImage = await transactionApi.upImage(formdata)
    },
    async handleSub() {
      if (this.ObverseImage === '') {
        this.$toast(this.$t('auth').tips3)
        return false
      }
      if (this.ReverseImage === '') {
        this.$toast(this.$t('auth').tips4)
        return false
      }
      if (this.basicConfig.IsHandCard && this.HoldImage === '') {
        this.$toast(this.$t('auth').tips5)
        return false
      }
      /* if (this.HoldImage === '') {
      } */
      const form = {
        HoldImage: this.HoldImage,
        ObverseImage: this.ObverseImage,
        ReverseImage: this.ReverseImage
      }
      await userApi.HightIdentityAuthentication(form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 45px 10px 20px;
  .content {
    .form {
      margin: 20px auto;
      border-radius: 4px;
      background: #17181e;
      padding: 15px;
      div {
        font-size: 14px;
        margin: 10px 0;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px auto 0;
      height: 40px;
      border-radius: 18px;
      width: 100%;
      background: #e143ac;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
